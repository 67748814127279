import { V2OrdersOrder } from '@wix/ambassador-membership-api/types'
import { CancellationEffectiveAt, ListOrdersRequest, Order } from '@wix/ambassador-pricing-plans-member-orders/types'
import type { IHttpClient } from '@wix/fe-essentials-viewer-platform/http-client'
import { PricingPlansAmbassador } from './pricingPlansAmbassador'
import { Experiments } from '@wix/thunderbolt-symbols'
import { orderV2toV1 } from './orderV2toV1'
import { memberListOrders, requestCancellation, createOnlineOrder } from '@wix/ambassador-pricing-plans-v2-order/http'

export type CancellationEffectiveAtEnum =
	| CancellationEffectiveAt.IMMEDIATELY
	| CancellationEffectiveAt.NEXT_PAYMENT_DATE

export class PricingPlansApi {
	constructor(
		private readonly ambassador: PricingPlansAmbassador,
		private readonly experiments: Experiments,
		private readonly httpClient: IHttpClient
	) {}

	startOnlineOrder = async (planId: string, startDate?: Date): Promise<V2OrdersOrder | Order> => {
		const useV2OrderEnabled = this.experiments['specs.thunderbolt.pricingPlansUserOrdersV2']
		if (useV2OrderEnabled) {
			const { data } = await this.httpClient.request(createOnlineOrder({ planId, startDate }))
			return orderV2toV1(data.order!)
		} else {
			const res = await (await this.ambassador.checkoutService()).createOnlineOrder({ planId, startDate })
			return res.order!
		}
	}

	requestMemberOrderCancellation = async (id: string, effectiveAt: CancellationEffectiveAtEnum): Promise<void> => {
		if (
			effectiveAt !== CancellationEffectiveAt.IMMEDIATELY &&
			effectiveAt !== CancellationEffectiveAt.NEXT_PAYMENT_DATE
		) {
			throw new Error('effectiveAt must be either IMMEDIATELY or NEXT_PAYMENT_DATE')
		}
		const useV2OrderEnabled = this.experiments['specs.thunderbolt.pricingPlansUserOrdersV2']
		if (useV2OrderEnabled) {
			await this.httpClient.request(requestCancellation({ id, effectiveAt }))
		} else {
			await (await this.ambassador.memberOrdersService()).requestCancellation({ id, effectiveAt })
		}
	}

	listCurrentMemberOrders = async (options: ListOrdersRequest): Promise<Array<Order>> => {
		const useV2OrderEnabled = this.experiments['specs.thunderbolt.pricingPlansUserOrdersV2']
		if (useV2OrderEnabled) {
			const res = await this.httpClient.request(memberListOrders(options))
			const orders = res?.data?.orders?.map((order) => orderV2toV1(order))
			return orders!
		}

		const response = await (await this.ambassador.memberOrdersService()).listOrders(options)
		return response.orders!
	}
}
