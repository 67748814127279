import { Order as OrderV1 } from '@wix/ambassador-pricing-plans-member-orders/types'
import { Order as OrderV2, PaymentStatus as V2PaymentStatus } from '@wix/ambassador-pricing-plans-v2-order/types'

export function orderV2toV1(orderV2: OrderV2): OrderV1 {
	const v1Order: OrderV1 = {
		id: orderV2.id,
		planId: orderV2.planId,
		subscriptionId: orderV2.subscriptionId,
		wixPayOrderId: orderV2.wixPayOrderId ?? undefined,
		startDate: orderV2.startDate ?? undefined,
		endDate: orderV2.endDate ?? undefined,
		createdDate: orderV2.createdDate ?? undefined,
		updatedDate: orderV2.updatedDate ?? undefined,
		earliestEndDate: orderV2.earliestEndDate ?? undefined,
		planName: orderV2.planName,
		planDescription: orderV2.planDescription,
		planPrice: orderV2.planPrice,
		freeTrialDays: orderV2.freeTrialDays ?? undefined,
		autoRenewCanceled: orderV2.autoRenewCanceled ?? undefined,
		lastPaymentStatus: toV1PaymentStatus(orderV2.lastPaymentStatus),
		orderMethod: orderV2.orderMethod,
		status: orderV2.status,
		statusNew: orderV2.statusNew,
		buyer: toV1Buyer(orderV2.buyer),
		cancellation: toV1Cancellation(orderV2.cancellation),
		currentCycle: toV1CurrentCycle(orderV2.currentCycle),
		cycles: toV1Cycles(orderV2.cycles),
		formData: toV1FormData(orderV2.formData),
		pausePeriods: toV1PausePeriods(orderV2.pausePeriods),
		pricing: toV1Pricing(orderV2.pricing),
		priceDetails: toV1PriceDetails(orderV2.priceDetails),
	}
	return v1Order
}

function toV1PaymentStatus(paymentStatus: OrderV2['lastPaymentStatus']): OrderV1['lastPaymentStatus'] {
	if (!paymentStatus) {
		return undefined
	}
	return V2PaymentStatus[paymentStatus]
}

function toV1Buyer(buyer: OrderV2['buyer']): OrderV1['buyer'] {
	if (!buyer) {
		return undefined
	}
	return {
		memberId: buyer?.memberId,
		contactId: buyer?.contactId,
	}
}

function toV1Cancellation(cancellation: OrderV2['cancellation']): OrderV1['cancellation'] {
	if (!cancellation) {
		return undefined
	}
	return {
		requestedDate: cancellation?.requestedDate ?? undefined,
		cause: cancellation?.cause,
		effectiveAt: cancellation?.effectiveAt,
	}
}

function toV1CurrentCycle(currentCycle: OrderV2['currentCycle']): OrderV1['currentCycle'] {
	if (!currentCycle) {
		return undefined
	}
	return {
		index: currentCycle?.index,
		startedDate: currentCycle?.startedDate ?? undefined,
		endedDate: currentCycle?.endedDate ?? undefined,
	}
}

function toV1Cycles(cycles: OrderV2['cycles']): OrderV1['cycles'] {
	if (!cycles) {
		return undefined
	}
	return cycles?.map((cycle) => ({
		index: cycle?.index,
		startedDate: cycle?.startedDate ?? undefined,
		endedDate: cycle?.endedDate ?? undefined,
	}))
}

function toV1FormData(formData: OrderV2['formData']): OrderV1['formData'] {
	if (!formData) {
		return undefined
	}
	return {
		formId: formData?.formId ?? undefined,
		submissionId: formData?.submissionId ?? undefined,
		submissionData: formData?.submissionData ?? undefined,
	}
}

function toV1PausePeriods(pausePeriods: OrderV2['pausePeriods']): OrderV1['pausePeriods'] {
	if (!pausePeriods) {
		return undefined
	}
	return pausePeriods?.map((period) => ({
		status: period.status,
		pauseDate: period.pauseDate ?? undefined,
		resumeDate: period.resumeDate ?? undefined,
	}))
}

function toV1Pricing(pricing: OrderV2['pricing']): OrderV1['pricing'] {
	if (!pricing) {
		return undefined
	}
	return {
		...pricing,
		subscription: {
			...pricing?.subscription,
			cycleDuration: {
				...pricing?.subscription?.cycleDuration,
				count: pricing?.subscription?.cycleDuration?.count || undefined,
			},
			cycleCount: pricing?.subscription?.cycleCount || undefined,
		},
		singlePaymentForDuration: {
			...pricing?.singlePaymentForDuration,
			count: pricing?.singlePaymentForDuration?.count || undefined,
		},
		singlePaymentUnlimited: pricing?.singlePaymentUnlimited || undefined,
		prices: pricing?.prices?.map((price) => ({
			duration: { ...price.duration, numberOfCycles: price.duration?.numberOfCycles || undefined },
			price: {
				...price.price,

				fees: price?.price?.fees || [],
			},
		})),
	}
}

function toV1PriceDetails(priceDetails: OrderV2['priceDetails']): OrderV1['priceDetails'] {
	if (!toV1PriceDetails) {
		return undefined
	}
	return {
		...priceDetails,
		freeTrialDays: priceDetails?.freeTrialDays || undefined,
		planPrice: priceDetails?.planPrice,
		singlePaymentForDuration: {
			...priceDetails?.singlePaymentForDuration,
			count: priceDetails?.singlePaymentForDuration?.count || undefined,
		},
		singlePaymentUnlimited: priceDetails?.singlePaymentUnlimited || undefined,
		subscription: {
			...priceDetails?.subscription,
			cycleDuration: {
				...priceDetails?.subscription?.cycleDuration,
				count: priceDetails?.subscription?.cycleDuration?.count || undefined,
			},
			cycleCount: priceDetails?.subscription?.cycleCount || undefined,
		},
		subtotal: priceDetails?.subtotal,
		tax: priceDetails?.tax,
		total: priceDetails?.total,
	}
}
